@import "tailwindcss/utilities";

.touch-action-manipulation {
    touch-action: manipulation;
}

.animate-loading {
    animation: animate-left-to-right 0.5s linear infinite;
}

@keyframes animate-left-to-right {
    0% {
        transform: translateX(-100%);
    }

    100% {
        transform: translateX(100%);
    }
}
